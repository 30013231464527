// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OnePager from './components/OnePager';
import PrivacyPolicy from './components/PrivacyPolicy';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<OnePager/>} />
        <Route path="/privacy" element={<PrivacyPolicy/>} />
      </Routes>
    </Router>
  );
};

export default App;
