// src/components/OnePager.js

import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import Logo from '../assets/logo.png';
import Screenshot1 from '../assets/screenshot1.png';
import Screenshot2 from '../assets/screenshot2.png';
import Screenshot3 from '../assets/screenshot3.png';
import Screenshot4 from '../assets/screenshot4.png';
import Screenshot5 from '../assets/screenshot5.png';
import Screenshot6 from '../assets/screenshot6.png';
import AppStoreButton from '../assets/appstore-button.png';
import { Link } from 'react-router-dom'; // Import Link for internal navigation
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const screenshots = [
  Screenshot1,
  Screenshot2,
  Screenshot3,
  Screenshot4,
  Screenshot5,
  Screenshot6,
];

const OnePager = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: true,
  };

  return (
    <Container>
      <Header>
        <LogoImg src={Logo} alt="Swipee Logo" />
        <AppName>Swipee</AppName>
      </Header>
      <Tagline>Swipe to clean your photo gallery effortlessly.</Tagline>
      <CarouselContainer>
        <Slider {...settings}>
          {screenshots.map((screenshot, index) => (
            <Screenshot
              key={index}
              src={screenshot}
              alt={`Screenshot ${index + 1}`}
            />
          ))}
        </Slider>
      </CarouselContainer>
      <ScreenshotsContainer>
        {screenshots.map((screenshot, index) => (
          <Screenshot
            key={index}
            src={screenshot}
            alt={`Screenshot ${index + 1}`}
          />
        ))}
      </ScreenshotsContainer>
      <Features>
        <FeatureTitle>Features</FeatureTitle>
        <FeatureList>
          <FeatureItem>Swipe right to save an image</FeatureItem>
          <FeatureItem>Swipe left to delete an image</FeatureItem>
          <FeatureItem>
            Keep your photo gallery organized and clean
          </FeatureItem>
          <FeatureItem>
            Fast, intuitive, and user-friendly interface
          </FeatureItem>
        </FeatureList>
      </Features>
      <DownloadSection>
        <a href="https://apps.apple.com/app/id6504521060" target="_blank" rel="noreferrer">
          <AppStoreImg
            src={AppStoreButton}
            alt="Download on the App Store"
          />
        </a>
      </DownloadSection>
      <SupportEmail href="mailto:hey@swipeeapp.com">hey@swipeeapp.com</SupportEmail>
      <Footer>
        <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        <FooterLink to="/privacy">Privacy Policy</FooterLink> {/* Assuming Privacy Policy is an internal link */}
      </Footer>
    </Container>
  );
};

export default OnePager;

// Styled Components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: 'Amatica SC', cursive;
  background: #f5f5f5;
  max-width: 100vw;
  overflow-x: hidden;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const LogoImg = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 15px;
  }
`;

const AppName = styled.h1`
  font-size: 2rem;
  color: #333;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Tagline = styled.p`
  font-size: 1.2rem;
  color: #666;
  margin: 20px 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  display: block;

  @media (min-width: 768px) {
    display: none;
  }

  .slick-dots {
    bottom: -30px;
  }

  .slick-slide img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
`;

const ScreenshotsContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding: 20px 0;
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Screenshot = styled.img`
  width: 100%;
  max-height: 80vh;
  object-fit: contain;

  @media (min-width: 768px) {
    width: 200px;
    height: 400px;
    margin: 0 10px;
  }
`;

const Features = styled.div`
  text-align: center;
  margin: 40px 0;
`;

const FeatureTitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 20px;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

const FeatureItem = styled.li`
  font-size: 1rem;
  color: #666;
  margin: 10px 0;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const DownloadSection = styled.div`
  margin: 40px 0;
  text-align: center;
`;

const AppStoreImg = styled.img`
  width: 150px;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 200px;
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const FooterLink = styled(Link)`
  color: #007bff;
  margin: 0 10px;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const SupportEmail = styled.a`
  display: block;
  margin-top: 10px;
  font-size: 2rem;
  color: #007BFF;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

