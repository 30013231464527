// src/components/PrivacyPolicy.js

import React from 'react';
import styled from 'styled-components';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Content>
        <h1>Privacy Policy for Swipee</h1>

        <Section>
          <h2>Effective Date: 24.06.24</h2>
        </Section>

        <Section>
          <h3>Introduction</h3>
          <p>
            Welcome to Swipee, a photo cleaner app designed to help you manage your photo gallery
            effortlessly. This privacy policy explains how we collect, use, disclose, and safeguard
            your information when you use our mobile application. Please read this privacy policy
            carefully. If you do not agree with the terms of this privacy policy, please do not
            use the application.
          </p>
        </Section>

        <Section>
          <h3>Information We Collect</h3>
          <h4>1. Personal Data</h4>
          <p>
            We do not collect personal data such as your name, email address, phone number, or any
            other personally identifiable information directly through our app.
          </p>

          <h4>2. Usage Data</h4>
          <p>
            When you access the app, we may collect certain information automatically, including,
            but not limited to, your device type, device identifier, operating system,
            application usage information, and crash reports. This information is used solely for
            analyzing app usage trends, improving user experience, and troubleshooting issues.
          </p>
        </Section>

        <Section>
          <h3>Third-Party Services</h3>
          <p>
            Swipee uses the following third-party services:
          </p>
          <ul>
            <li><strong>Firebase Analytics:</strong> We utilize Firebase Analytics to collect and analyze usage data mentioned above.</li>
            <li><strong>RevenueCat:</strong> We use RevenueCat for managing in-app subscriptions and related analytics.</li>
          </ul>
        </Section>

        <Section>
          <h3>How We Use Your Information</h3>
          <p>
            We use the information we collect to analyze app usage patterns, improve our services,
            provide personalized content and recommendations, and troubleshoot issues.
          </p>
        </Section>

        <Section>
          <h3>Security of Your Information</h3>
          <p>
            We prioritize the security of your information and use industry-standard measures to
            protect it from unauthorized access or disclosure.
          </p>
        </Section>

        <Section>
          <h3>Changes to This Privacy Policy</h3>
          <p>
            We may update this privacy policy from time to time. We will notify you of any changes
            by posting the new privacy policy on this page. You are advised to review this privacy
            policy periodically for any changes.
          </p>
        </Section>

        <Section>
          <h3>Contact Us</h3>
          <p>
            If you have any questions or concerns about this privacy policy, please contact us at
            hey@swipeeapp.com.
          </p>
        </Section>
      </Content>
    </Container>
  );
};

export default PrivacyPolicy;

// Styled Components

const Container = styled.div`
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background: #f5f5f5;
  max-width: 100%;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;

  h1, h2, h3, h4 {
    color: #333;
  }

  p {
    color: #666;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
    color: #666;
  }
`;

const Section = styled.div`
  margin-bottom: 30px;

  h2, h3, h4 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 15px;
  }
`;
